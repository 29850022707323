import React from "react";
import { Header } from "../../components/header/header";
import { useJourney } from "../../providers/journey_provider";
import { Container } from "../../components/page/container";
import Button from "../../components/button";
import { Card } from "../../components/landing/card";
import { Footer } from "../../components/footer/footer";
import "./landing.css";
import { EarlyAccess } from "../../components/early_access/early_access";
import { useEarlyAccess } from "../../providers/go_to_early_access.provider";
import DemoVideo from "./VideoPopUp";
import ReactPlayer from "react-player"; //
import { useNavigate } from "react-router-dom";

export const Landing = () => {
  const { content } = useJourney();
  return content ? (
    <div className="bg-gray-100">
      <HeroBgImage src={content.hero_bg_image} />
      <Container>
        <Hero image={content.hero_secondary_image} />
        <IntroductionParagraph text={content.paragraph_1} />
        <div className="flex justify-center">
          <div className="w-11/12">
            <Cards cards={content.cards} />
          </div>
        </div>
        <Question question={content.question} />
        <EarlyAccess />
        <Footer />
      </Container>
    </div>
  ) : null;
};

const HeroBgImage = ({ ...props }) => (
  <img
    {...props}
    className="h-screen w-full absolute top-0 left-0 z-0 object-cover"
    style={{ filter: "brightness(0.3)" }}
  />
);

const Question = ({ question }) => (
  <h1 className="text-center text-3xl py-10">{question}</h1>
);

const Hero = ({ image, earlyAccessRef }) => (
  <div className="hero">
    <Header />
    <div className="flex flex-col-reverse lg:items-center h-screen justify-center lg:flex-row gap-6">
      <HeroIntro />
      <HeroImage src={image} />
    </div>
  </div>
);

const HeroImage = ({ ...props }) => {
  return <img {...props} className="w-1/3 lg:w-1/2 hidden lg:block" />;
};

const HeroIntro = ({ earlyAccessRef }) => {
  const { goToEarlyAccess } = useEarlyAccess();
  const { content } = useJourney();
  return (
    <div className="flex flex-col gap-4 text-left">
      <h1 className="text-xl text-white/60 font-bold">{content.slogan}</h1>
      <h1 className="py-4 text-4xl lg:text-5xl font-bold text-white helvetica-title">
        {content.hero_title}
      </h1>
      <p className="text-md xl:text-lg text-white font-light">
        {content.hero_subtitle}
      </p>
      <HeroButtons />
    </div>
  );
};

const HeroButtons = () => {
  const { goToEarlyAccess } = useEarlyAccess();
  const navigate = useNavigate();
  return (
    <div className="flex md:gap-6 items-center flex-col md:flex-row">
      <Button className="mt-4" onClick={goToEarlyAccess}>
        Request Invite
      </Button>
      <Button
        className="mt-4 text-white"
        variant="transparent"
        onClick={() => navigate("/demo")}
      >
        Watch Demo
      </Button>
    </div>
  );
};

const IntroductionParagraph = ({ text }) => (
  <div className="py-12">
    <p className="text-gray-500 text-center">{text}</p>
  </div>
);

const Cards = ({ cards }) => (
  <div className="flex justify-center gap-10 flex-col">
    <h1 className="font-bold text-center text-3xl">Why ViviLife?</h1>
    {cards.map((card, index) => (
      <Card key={index} {...card} index={index} />
    ))}
  </div>
);
