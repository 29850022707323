import { Link } from "react-router-dom";
import Button from "../button";

export const HeaderLink = ({ to, children, cta, ...props }) => {
    return cta ? (
      <Button className="text-md" onClick={props.onClick}>
        {children}
      </Button>
    ) : (
      <Link className="text-md" to={to} {...props}>
        {children}
      </Link>
    );
  };