import { createContext, createRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

const EarlyAccessContent = createContext();

export const useEarlyAccess = () => {
  const early_access = useContext(EarlyAccessContent);
  return early_access;
};

export const EarlyAccessContentProvider = ({ children }) => {
  const early_access_ref = createRef(null);
  const navigate = useNavigate();

  const goToEarlyAccess = async () => {
    if (window.location.pathname != "/") {
      navigate("/");
      await sleep(1000);
      console.log("slept");
    }
    scrollToEarly();
  };

  const sleep = async (ml) => await new Promise(() => setTimeout(() => {}, ml));

  const scrollToEarly = () =>
    early_access_ref.current?.scrollIntoView({ behavior: "smooth" });

  return (
    <EarlyAccessContent.Provider
      value={{
        early_access_ref,
        goToEarlyAccess,
      }}
    >
      {children}
    </EarlyAccessContent.Provider>
  );
};
