import React from "react";
import logo from "../../assets/images/logo_black.webp";
import { BsInstagram } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
import { CoachSelector } from "../header/header";
import { Link } from "react-router-dom";
import { useJourney } from "../../providers/journey_provider";
import contactCards from "../../assets/content/contact";

const aboutItems = [
  { title: "Our mission", postId: "" },
  { title: "The team", postId: "" },
  { title: "Our story", postId: "" },
  { title: "Contact", postId: "" },
];

export const Footer = () => {
  return (
    <div className="pt-20 text-black">
      <div className="flex flex-col md:flex-row items-center mb-6">
        <FooterGeneral />
        <FooterLinks />
        <CoachSelector />
      </div>
      <div>
        <p className="text-center text-black/40 mt-20 font-light text-xs md:text-md">
          COPYRIGHT © 2024 VIVILIFE. ALL RIGHTS RESERVED.
        </p>
      </div>
    </div>
  );
};

const FooterLinks = () => {
  const { content } = useJourney();
  return (
    <div className="flex gap-4 justify-around w-full">
      <FooterSection title="About" items={aboutItems} section="about" />
      <FooterSection title="Blog" items={content.blog} section="blog" />
    </div>
  );
};

const FooterGeneral = () => {
  const { content } = useJourney();
  return (
    <div className="flex flex-col items-center mb-6">
      <img src={logo} alt="logo" className="w-4/5" />
      <p className="whitespace-nowrap text-[10px] py-4 text-black/80">
        {content.slogan}
      </p>
      <SocialMedia />
    </div>
  );
};

const SocialMedia = () => (
  <div className="flex flex-row bg-black rounded-md items-center p-1">
    {socialMedia.map((card, i) => (
      <SocialLink Icon={card.Icon} link={card.link} size={card.size} key={i} />
    ))}
  </div>
);

const SocialLink = ({ Icon, link, size }) => (
  <a href={link} target="_blank" className="text-white p-2">
    <Icon size={size} className="text-2xl mx-2" />
  </a>
);

const FooterLink = ({ title, to, section }) => (
  <Link className="text-sm" to={`/${section}/${to}`}>
    {title}
  </Link>
);

const FooterSection = ({ items, title, section }) => {
  return (
    <div className="flex flex-col mb-6">
      <h1 className="text-lg font-bold">{title}</h1>
      {items.map((item, i) => (
        <FooterLink
          title={item.title}
          to={item.postId}
          section={section}
          key={i}
        />
      ))}
    </div>
  );
};

const socialMedia = [contactCards[3], contactCards[2], contactCards[1]];
