import { MdEmail } from "react-icons/md";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaTiktok } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";

const contactCards = [
    {
        Icon: MdEmail,
        title: "info@vivilife.me",
        link: "mailto:info@vivilife.me",
        size: 20,
    },
    {
        Icon: FaLinkedinIn,
        title: "vivilife",
        link: "https://www.linkedin.com/company/vivilife",
        size: 18,
    },
    {
        Icon: FaTiktok,
        title: "vivilife.app",
        link: "https://www.tiktok.com/@vivilife.app",
        size: 16,
    },
    {
        Icon: AiFillInstagram,
        title: "vivilife.app",
        link: "https://www.instagram.com/vivilife.app/",
        size: 20,
    },
]

export default contactCards;