import stream from "../../../images/blog/stream.webp";

const streamline_your_schedule = {
    title: "Streamline Your Schedule",
    subtitle: "Tips for Coaches on ViviLife",

    introduction: "Managing a busy schedule can be challenging, especially for coaches. On ViviLife, you have the tools to streamline your schedule and make the most of your time. This article will show you how to organize your activities and maximize your productivity."
        + "\nMany coaches struggle to balance their personal and professional lives. Without effective scheduling, it's easy to feel overwhelmed and miss important tasks. ViviLife provides features that help you stay organized and on top of your schedule."
        + "\nViviLife is a social media app designed for health and wellness coaches. It offers tools like calendar integration, advanced settings, and analytics to help you manage your time efficiently. By using these features, you can better organize your activities and focus on what matters most.",
    introImg: stream,

    contentType: 1,
    content: [
        { highlight: "Use Calendar Integration", paragraph: "Sync your events, coaching sessions, and personal appointments with ViviLife's calendar. This helps you see your entire schedule in one place and avoid double-booking." },
        { highlight: "Plan Ahead ", paragraph: "Create a content calendar to plan your posts, live sessions, and check-ins. Planning ahead ensures you stay consistent and cover important topics without last-minute stress." },
        { highlight: "Set Priorities", paragraph: "Use ViviLife’s advanced settings to prioritize your tasks. Focus on high-impact activities first, like coaching sessions and community events, and leave less critical tasks for later." },
        {highlight: "Automate Routine Tasks", paragraph: "Take advantage of ViviLife's automation features to handle routine tasks like sending reminders or posting updates. This frees up time for more important activities."},
    ],
    contentImg: null,
    conclusion: "Streamlining your schedule on ViviLife can help you manage your time effectively and reduce stress. By using calendar integration, planning ahead, setting priorities, automating tasks, and tracking your time, you can focus on what matters most and improve your productivity.",
};

export default streamline_your_schedule;