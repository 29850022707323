import React from "react";
import Demo from "../../assets/images/demo.mp4";
import Logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { IoIosClose } from "react-icons/io";

const DemoVideo = () => (
  <div>
    <div className="flex justify-center items-center bg-black py-2">
      <img src={Logo} alt="logo" className="h-12" />
    </div>
    <video controls autoPlay loop muted className="w-full h-full" src={Demo} />
    <Link to="/" className="absolute top-0 right-0 m-4 text-white">
      <IoIosClose size={40} className="cursor-pointer" />
    </Link>
  </div>
);
export default DemoVideo;
