import impact from "../../../images/blog/impact.webp";
const make_a_bigger_impact = {
    title: "Make a Bigger Impact",
    subtitle: "Helping Coaches Lead Their Communities on ViviLife",
    introduction: "In today's busy world, health and wellness communities help people feel better and grow. As a coach on ViviLife, you can make a big difference. This article will show you how to inspire and lead your community to make a bigger impact.\nMany coaches find it hard to keep their community motivated and engaged. This can stop the community from growing and being effective. ViviLife gives you tools to solve this problem and become a better leader.\nViviLife is a social media app for health and wellness. Coaches can create communities, share content, start challenges, and hold events. Using these features well can help you make a bigger impact.",
    introImg: impact,
    contentType: 1,
    content: [
        { highlight: "Be Active and Visible ", paragraph: "Use ViviLife's content sharing tools to post updates, tips, and motivational content regularly. This shows your commitment and helps members feel connected." },
        { highlight: "Create a Supportive Space", paragraph: "Use group chats and community feeds to encourage members to share their experiences and challenges. Celebrate small successes to keep everyone motivated." },
        { highlight: "Use ViviLife's Tools", paragraph: "Take advantage of ViviLife's features like calendar integration and analytics. These tools help you stay organized and give personalized guidance." },
        { highlight: "Stay Consistent", paragraph: "Develop a schedule for your posts, challenges, and events. Consistency helps keep members engaged and on track." },
    ],
    contentImg: null,
    conclusion: "As a coach on ViviLife, you can make a big impact by being active, supportive, and consistent. Use ViviLife's tools to lead your community and help members improve their well-being.",
};

export default make_a_bigger_impact;