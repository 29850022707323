import React from "react";
import { Select, Space } from "antd";
import community_types from "../../assets/content/community_types.json";

const Selection = ({ update }) => (
  <Space
    style={{
      width: "100%",
    }}
    direction="vertical"
  >
    <Select
      mode="multiple"
      allowClear
      style={{
        width: "100%",
        borderRadius: "10px",
      }}
      placeholder="Input here your affinities"
      onChange={update}
      options={community_types}
    />
  </Space>
);
export default Selection;
