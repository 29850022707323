import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Header } from "../header/header";
import { Footer } from "../footer/footer";
import { Container } from "../page/container";
import Button from "../button";
import { useJourney } from "../../providers/journey_provider";
import { BsCheck } from "react-icons/bs";

const BlogPost = () => {
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const { content } = useJourney();

  useEffect(() => {
    {
      content.blog.map((post) => {
        if (post.postId === postId) {
          setPost(post.to);
        }
      });
    }
  }, [postId]);

  return (
    <div className="bg-gray-100">
      <Container>
        <Header />
        {post && (
          <div className="mt-28">
            <Title post={post} />
            <Introduction post={post} />
            <Content post={post} />
          </div>
        )}
        <BackButton />
        <Footer />
      </Container>
    </div>
  );
};

const Title = ({ post }) => {
  return (
    <div className="mt-6 mb-12">
      <h1 className="text-center text-4xl font-bold">{post.title}</h1>
      <p className="text-center text-xl py-4 text-gray-400">{post.subtitle}</p>
    </div>
  );
};

const Introduction = ({ post }) => {
  return (
    <div className="my-12">
      <div className="flex flex-row gap-6">
        {post.introImg && (
          <img
            src={post.introImg}
            alt="Blog image"
            className="hidden sm:block w-1/2 rounded-xl"
          />
        )}
        <p className="text-justify text-lg">{post.introduction}</p>
      </div>
    </div>
  );
};

const Content = ({ post }) => {
  return (
    //content type 1: text and image column
    //content type 2: text only
    //content type 3: text and image row
    <>
      {post.contentType === 1 && (
        <div className="my-12 flex flex-col gap-6">
          {post.content.map((content, i) => (
            <ContentParagraph content={content} key={i} />
          ))}
          {post.contentImg && (
            <img
              src={post.contentImg}
              alt="Blog image"
              className="rounded-xl"
            />
          )}
          <Conclusion post={post} />
        </div>
      )}

      {post.contentType === 2 && (
        <div className="my-12 flex flex-col gap-6">
          <p className="text-justify">{post.content}</p>
        </div>
      )}

      {post.contentType === 3 && (
        <div className="my-12 flex flex-row gap-6">
          <img
            src={post.contentImg}
            alt="Blog image"
            className="hidden sm:block w-1/2 rounded-xl"
          />
          <p className="text-justify text-lg">{post.content}</p>
        </div>
      )}
    </>
  );
};

const ContentParagraph = ({ content }) => {
  return (
    <div>
      <h1 className="text-3xl font-medium flex gap-2 items-center">
        <BsCheck className="text-3xl" color="green" />
        {content.highlight}
      </h1>
      <p className="text-justify text-lg mt-2 ml-10">{content.paragraph}</p>
    </div>
  );
};

const Conclusion = ({ post }) => {
  return (
    <div className="my-12">
      <p className="text-lg">{post.conclusion}</p>
    </div>
  );
};

const BackButton = () => {
  return (
    <div className="flex justify-center">
      <Button>
        <Link to="/blog">Back to Blog</Link>
      </Button>
    </div>
  );
};

export default BlogPost;
