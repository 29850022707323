import img_monetize from "../../../images/blog/monetize.webp";

const monetize = {
    title: "Monetize",
    subtitle: "A Guide for Coaches on ViviLife",

    introduction: "Are you a coach looking to turn your passion into profit? On ViviLife, you can build a thriving community and monetize your efforts. This article will show you how to make money while helping others achieve their health and wellness goals."
        + "\nMany coaches want to earn money from their communities but don't know where to start. Without a clear plan, it can be hard to balance helping others with making a profit. ViviLife provides tools to help you do both."
        + "\nViviLife is a social media app focused on health and wellness. Coaches can create communities, share content, hold events, and offer paid services. By using these features effectively, you can generate income while supporting your community.",
    introImg: img_monetize,

    contentType: 1,
    content: [
        { highlight: "Offer Exclusive Content ", paragraph: "Create a subscription model where members pay for access to special content like videos, articles, and tips. This adds value to your community and generates steady income." },
        { highlight: "Host Paid Events", paragraph: "Use ViviLife to promote and manage paid events. These can be online workshops, fitness classes, or in-person gatherings. Charging a fee for these events helps cover costs and brings in extra revenue." },
        { highlight: "Provide One-on-One Coaching", paragraph: "Offer personalized coaching sessions for a fee. Use ViviLife’s easy booking system to schedule and manage these sessions. Personalized coaching can be a significant source of income." },
        {
            highlight: "Use Analytics to Improve and Promote", paragraph: "Take advantage of ViviLife's analytics to understand what your community likes best. Use this information to improve your offerings and promote the most popular services and content."},
    ],
    contentImg: null,
    conclusion: "Monetizing your community on ViviLife is a great way to turn your passion into profit. By offering exclusive content, hosting paid events, and providing one-on-one coaching, you can generate income while helping others achieve their health and wellness goals.",
};

export default monetize;