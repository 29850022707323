import React from "react";
import { Link } from "react-router-dom";
import "./card.css";

export const Card = ({ image, title, description, link, icon, index }) => {
  return (
    <div
      className={`flex bg-white landing-card shadow-md rounded-xl border items-center justify-center ${
        index % 2 != 0 ? "flex-row-reverse" : ""
      }`}
    >
      <div className="justify-start hidden lg:flex h-full w-1/2 ">
        <img src={image} alt="" />
      </div>
      <CardText
        title={title}
        description={description}
        link={link}
        icon={icon}
      />
    </div>
  );
};

const CardText = ({ title, description, link, icon }) => {
  return (
    <div className="flex md:w-1/2 justify-center items-center md:items-start flex-col gap-4 p-6 text-justify">
      <img src={icon} alt="" className="w-6" />
      <h2 className="font-bold text-xl">{title}</h2>
      <p className="text-black text-opacity-50 text-light text-lg">
        {description}
      </p>
      <Link className="font-light hover:underline text-lg" to={link.url}>
        → {link.text}
      </Link>
    </div>
  );
};
