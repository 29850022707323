import { createContext, useContext, useEffect, useState } from "react";
import coach_content from "../assets/content/coach";
import community_content from "../assets/content/community";

const JourneyContext = createContext();
export const JourneyProvider = ({ children }) => {
  const [selectedJourney, setSelectedJourney] = useState(null);
  const [content, setContent] = useState({});
  const selectJourney = (journey) => setSelectedJourney(journey);
  const switchJourney = () => {
    setSelectedJourney((prev) => (prev === "coach" ? "community" : "coach"));
  };
  useEffect(() => {
    setContent(selectedJourney === "coach" ? coach_content : community_content);
  }, [selectedJourney]);

  return (
    <JourneyContext.Provider
      value={{ selectedJourney, selectJourney, switchJourney, content }}
    >
      {children}
    </JourneyContext.Provider>
  );
};
export const useJourney = () => useContext(JourneyContext);
