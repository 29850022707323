import React from "react";
import logo from "../../assets/images/logo.png";
import coachImage from "../../assets/images/coach_journey.webp";
import communityImage from "../../assets/images/community_journey.webp";
import "./select_journey.css";
import { useJourney } from "../../providers/journey_provider";
import Button from "../../components/button";

export const SelectJourney = () => {
  return (
    <div className="flex h-screen overflow-hidden flex-col xl:flex-row">
      <SelectJourneyOption {...jorneys.coach} />
      <SelectJourneyOption {...jorneys.community} />
    </div>
  );
};

const SelectJourneyOption = ({ paragraph, buttonText, image, journey }) => {
  const { selectJourney } = useJourney();
  return (
    <div className="flex-1 relative flex items-center justify-center hoverable-journey flex-col gap-2">
      <img
        src={image}
        className="hoverable-journey-background top-0 left-0 absolute -z-10 min-h-screen object-cover"
        alt=""
      />
      <img src={logo} className="w-1/3" alt="" />
      <p className="text-white text-opacity-80 font-bold">{paragraph}</p>
      <Button className="mt-4 w-[12rem]" onClick={() => selectJourney(journey)}>
        {buttonText}
      </Button>
    </div>
  );
};

const jorneys = {
  coach: {
    paragraph: "MAKE YOUR PASSION PROFITABLE",
    buttonText: "Become a Coach",
    image: coachImage,
    journey: "coach",
  },
  community: {
    paragraph: "GROW WITH COACHES AND COMMUNITIES",
    buttonText: "Start now",
    image: communityImage,
    journey: "community",
  },
};
