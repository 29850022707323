const button_style =
  "rounded-full py-4 px-6 font-bold text-sm shadow-md hover:shadow-lg transition-all delay-75 w-[10rem]";
const white = "bg-white text-black hover:bg-opacity-80 text-opacity-70"
const black = "bg-black text-white hover:bg-gray-100";
const transparent = "bg-transparent text-black border border-white border-opacity-30 border-2 hover:border-opacity-100";
const variants = { white, black, transparent };

const Button = ({ children, variant = "white", ...props }) => {
  return (
    <button
      {...props}
      className={`${button_style} ${variants[variant]} ${props.className}`}
    >
      {children}
    </button>
  );
};

export default Button;
