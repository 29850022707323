import hero_bg_image from "../images/community_journey.webp";
import hero_secondary_image from "../images/community_hero.webp";
import access_exclusive_community_content from "./blog/community/access_exclusive_community_content";
import connect_with_professional_coaches from "./blog/community/connect_with_professional_coaches";
import join_events_with_like_minded_people from "./blog/community/join_events_with_like_minded_people";
import exclusive from "../images/blog/exclusive.webp";
import connect from "../images/blog/connect.webp";
import join from "../images/blog/join.webp";
import connect_with_coaches_image from "../images/connect_with_coaches.webp";
import explore_exclusive_content_image from "../images/explore_exclusive_content.webp";
import join_events_image from "../images/join_events.webp";
import card_1_icon from "../images/icons/multiple_people.png";
import card_2_icon from "../images/icons/connect.png";
import card_3_icon from "../images/icons/calendar_check.png";

const community = {
  slogan: "GROW WITH COACHES AND COMMUNITIES",
  hero_bg_image: hero_bg_image,
  hero_title: "All-in-One Platform Connect, Engage, Transform and Grow",
  hero_secondary_image: hero_secondary_image,
  hero_subtitle:
    "Elevate Your Wellness Journey. Join ViviLife and discover the ultimate platform designed for individuals committed to health and wellness. Connect with expert coaches, access exclusive content and achieve your wellness goals in a supportive community.",
  paragraph_1:
    "At ViviLife, we empower you to take control of your wellness journey with the support of expert coaches and a vibrant community. Our platform is designed to help you achieve your health goals, providing the tools and guidance you need every step of the way",
  paragraph_2: "Ready to Elevate Your Coaching Practice?",
  cards: [
    {
      icon: card_1_icon,
      image: explore_exclusive_content_image,
      title: "Access Exclusive Community Content",
      description:
        "Ready to unlock exclusive content? Join a premium community on ViviLife today and start your journey to better health and wellness!",
      link: {
        text: "Learn how to unlock exclusive content",
        url: "/blog/access-exclusive-community-content",
      },
    },
    {
      icon: card_2_icon,
      image: connect_with_coaches_image,
      title: "Connect with Professional Coaches",
      description:
        "Connecting with professional coaches on ViviLife can transform your health and wellness journey. Get personalized guidance, expert knowledge, and the motivation you need to succeed.",
      link: {
        text: "Learn more about professional coaches",
        url: "/blog/connect-with-professional-coaches",
      },
    },
    {
      icon: card_3_icon,
      image: join_events_image,
      title: "Join Events with Like-Minded People",
      description:
        "Joining events with like-minded people on ViviLife can greatly enhance your health and wellness journey. Build connections, stay motivated, learn new skills, and have fun along the way.",
      link: {
        text: "Learn more about joining events",
        url: "/blog/join-events-with-like-minded-people",
      },
    },
  ],
  mission:
    "Our mission is to empower individuals to achieve their full potential and transform their lives through our innovative platform.",
  form_type: "What type of community do you want to join?",
  question: "Ready to  Transform your wellness journey?",
  blog: [
    {
      img: exclusive,
      title: "Access Exclusive Community Content ",
      postId: "access-exclusive-community-content",
      to: access_exclusive_community_content,
    },
    {
      img: connect,
      title: "Connect with Professional Coaches ",
      postId: "connect-with-professional-coaches",
      to: connect_with_professional_coaches,
    },
    {
      img: join,
      title: "Join Events with Like-Minded People ",
      postId: "join-events-with-like-minded-people",
      to: join_events_with_like_minded_people,
    },
  ],
  blogText: "Learn about ViviLife's Commitment to Users ",
};

export default community;
