import { useState } from "react";

export const useForm = (initialValues) => {
  const [values, setValues] = useState(initialValues);

  const update = (key, value) => {
    setValues((prev) => ({ ...prev, [key]: value }));
  };

  return { values, update };
};
