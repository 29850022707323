import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import { SelectJourney } from "./pages/select_journey/select_journey";
import { JourneyProvider, useJourney } from "./providers/journey_provider";
import { Landing } from "./pages/landing/landing";
import { BlogRoutes } from "./pages/blog";
import { EarlyAccessContentProvider } from "./providers/go_to_early_access.provider";
import About from "./pages/about";
import DemoVideo from "./pages/landing/VideoPopUp";
// import "./firebase";

const App = () => {
  return (
    <JourneyProvider>
      <AppRouter />
    </JourneyProvider>
  );
};

const AppRouter = () => {
  const { selectedJourney } = useJourney();
  return (
    <BrowserRouter>
      {selectedJourney ? <AppRoutes /> : <SelectJourneyRoutes />}
    </BrowserRouter>
  );
};

const SelectJourneyRoutes = () => (
  <Routes>
    <Route path="/" element={<SelectJourney />} />
    <Route path="/*" element={<Navigate to="/" />} />
  </Routes>
);

const AppRoutes = () => (
  <EarlyAccessContentProvider>
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/Blog/*" element={<BlogRoutes />} />
      <Route path="/About/" element={<About />} />
      <Route path="/demo" element={<DemoVideo />} />
    </Routes>
  </EarlyAccessContentProvider>
);

export default App;
