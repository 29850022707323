import exclusive from "../../../images/blog/exclusive.webp";

const access_exclusive_community_content = {
    title: "Access Exclusive Community Content ",
    subtitle: null,

    introduction: "Unlock a world of exclusive content designed just for you! On ViviLife, you can access unique health and wellness materials that help you achieve your goals. This article will show you how to make the most of ViviLife's exclusive content."
        + "\n Finding reliable and high-quality health and wellness content can be tough. With so much information out there, it’s easy to feel overwhelmed. ViviLife offers a solution by providing exclusive, high-quality content curated by professional coaches.",
    introImg: exclusive,
    contentType: 1,
    content: [
        { highlight: "Expert Advice ", paragraph: "Get tips and strategies from certified coaches who know their stuff. Enjoy access to professional insights and advice that you won't find anywhere else." },
        { highlight: "Specialized Programs ", paragraph: "Join tailored programs that suit your specific needs, whether you're looking to lose weight, build muscle, or improve mental wellness. These programs are designed to help you achieve your goals efficiently." },
        { highlight: "Unique Materials ", paragraph: "Access exclusive videos, articles, and guides that are not available to the general public. This premium content is designed to give you the edge in your wellness journey." },
        {highlight: "Community Support ", paragraph: "Engage with other members who are on the same journey as you. Share your progress, ask questions, and get support from like-minded individuals."
        },
    ],
    contentImg: null,
    conclusion: "Accessing exclusive content on ViviLife can significantly enhance your health and wellness journey. Join a premium community to unlock expert advice, specialized programs, and unique materials that will help you achieve your goals.",
};

export default access_exclusive_community_content;