import React from "react";
import "./header.css";
import logo from "../../assets/images/logo.png";
import { useJourney } from "../../providers/journey_provider";
import { useNavigate } from "react-router-dom";
import Switch from "../switch";
import menu_options from "../../assets/images/icons/menu_options.png";
import { useEarlyAccess } from "../../providers/go_to_early_access.provider";
import { HeaderLink } from "./headerLink";

export const Header = ({ earlyAccessRef }) => {
  const [headerOpen, setHeaderOpen] = React.useState(false);
  return (
    <div className="fixed w-full flex items-center justify-center top-6 left-0 z-20">
      <div className="rounded-full w-11/12 flex py-6 md:py-2 pl-6 pr-2 justify-between items-center header-bg text-white max-w-6xl">
        <Logo />
        <div className="invisible md:visible">
          <CoachSelector />
        </div>
        <div className="hidden md:flex items-center gap-2">
          <Links earlyAcessRef={earlyAccessRef} />
        </div>
        <OptionsButton onClick={() => setHeaderOpen(true)} />
      </div>
      {headerOpen && <HeaderOpen close={() => setHeaderOpen(false)} />}
    </div>
  );
};

const OptionsButton = ({ onClick }) => {
  return (
    <div
      className="md:hidden flex items-center gap-2 cursor-pointer"
      onClick={onClick}
    >
      <img src={menu_options} className="w-6" />
    </div>
  );
};

const HeaderOpen = ({ close }) => {
  return (
    <div className="p-10 justify-between items-center bg-black text-white mb-10 fixed h-screen w-screen top-0 left-0">
      <div className="flex items-center justify-between">
        <Logo /> <OptionsButton onClick={close} />
      </div>
      <CoachSelector />
      <LinkHeaderOpen close={close} />
    </div>
  );
};

const LinkHeaderOpen = ({ close }) => (
  <div className="flex items-start flex-col w-full gap-4 mt-10">
    <HeaderLink to="/blog" onClick={close}>
      Blog
    </HeaderLink>
    <HeaderLink to="/about" onClick={close}>
      About
    </HeaderLink>
    <HeaderLink onClick={close} to="/request_invite" cta>
      Request Invite
    </HeaderLink>
  </div>
);

const Logo = () => {
  const navigate = useNavigate();
  return (
    <img
      src={logo}
      className=" w-20 md:w-28 xl:w-32 cursor-pointer"
      onClick={() => {
        navigate("/");
      }}
    />
  );
};

export const CoachSelector = () => {
  const { switchJourney, selectedJourney } = useJourney();
  return (
    <div className="flex gap-2 items-center">
      <label className="text-sm whitespace-nowrap">
        {selectedJourney == "community" ? "Im a coach" : "Im not a coach"}
      </label>
      <Switch onClick={switchJourney} value={selectedJourney == "coach"} />
    </div>
  );
};

const Links = () => {
  const { goToEarlyAccess } = useEarlyAccess();

  return (
    <div className="flex gap-4 items-center flex-row">
      <HeaderLink to="/blog">Blog</HeaderLink>
      <HeaderLink to="/about">About</HeaderLink>
      {/* <HeaderLink to="/about">About</HeaderLink> */}
      <HeaderLink onClick={goToEarlyAccess} cta>
        Request Invite
      </HeaderLink>
    </div>
  );
};
