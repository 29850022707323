import { Footer } from "../components/footer/footer";
import { Header } from "../components/header/header";
import { Container } from "../components/page/container";
import { MdEmail } from "react-icons/md";
import { ImLinkedin } from "react-icons/im";
import klajdi from "../assets/images/about/klajdi.png";
import gonzalo from "../assets/images/about/gonzalo.png";
import { EarlyAccess } from "../components/early_access/early_access";
import contactCards from "../assets/content/contact";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const About = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="bg-gray-100">
      <Container>
        <Header />
        <div className="h-28" />
        <Card index={0} />
        <Team />
        <Card index={1} />
        <Contact />
        <EarlyAccess />
        <Footer />
      </Container>
    </div>
  );
};

const Card = ({ index }) => {
  return (
    <div>
      <h1 className="text-center text-3xl font-bold">{cards[index].title}</h1>
      <div className="bg-black rounded-xl mt-6">
        <p className="text-white p-12 text-justify font-light">
          {cards[index].paragraph}
        </p>
      </div>
    </div>
  );
};

const Team = () => {
  return (
    <div className="mt-12 mb-12">
      <h1 className="text-3xl text-center font-bold">The Team</h1>
      <div className="flex flex-col justify-evenly sm:flex-row mt-6">
        {teamates.map((teamate, i) => (
          <Teamate teamate={teamate} key={i} />
        ))}
      </div>
    </div>
  );
};

const Teamate = ({ teamate }) => {
  return (
    <div className="mb-12 sm:mt-0 bg-black rounded-xl text-white text-center">
      <div className="flex justify-center p-6 pt-12">
        <img src={teamate.img} alt={teamate.name} className="w-2/3 sm:w-4/5" />
      </div>
      <h1 className="text-sm font-bold mt-4">{teamate.role}</h1>
      <h1 className="font-light text-sm mt-2">{teamate.name}</h1>
      <div className="flex flex-row justify-center mt-4 mb-12 items-center">
        <a href={teamate.email} target="_blank">
          <MdEmail size={20} className="mr-4" />
        </a>
        <a href={teamate.linkedin} target="_blank">
          <ImLinkedin size={17} />
        </a>
      </div>
    </div>
  );
};

const Contact = () => {
  return (
    <div className="flex justify-center mt-12 mb-24">
      <div className="w-8/12">
        <h1 className="text-3xl text-center font-bold">Contact Us</h1>
        <div className="mt-6 sm:grid sm:grid-cols-2 gap-12">
          {contactCards.map((card, i) => (
            <ContactCard card={card} Icon={card.Icon} key={i} />
          ))}
        </div>
      </div>
    </div>
  );
};

const ContactCard = ({ Icon, card }) => {
  return (
    <a
      href={card.link}
      target="_blank"
      className="bg-black rounded-xl text-white text-center flex flex-row items-center p-4 justify-center my-6"
    >
      <Icon size={20} />
      <h1 className="text-sm font-medium mx-4">{card.title}</h1>
    </a>
  );
};

const teamates = [
  {
    img: klajdi,
    role: "CEO & Co-Founder",
    name: "Klajdi Beqiraj",
    email: "mailto:klajdi@vivilife.me",
    linkedin: "https://www.linkedin.com/in/klajdi-beqiraj-1998-leven/",
  },
  {
    img: gonzalo,
    role: "CTO & Co-Founder",
    name: "Gonzalo Alonso",
    email: "mailto:info@vivilife.me",
    linkedin: "https://www.linkedin.com/in/galonsog/",
  },
];

const cards = [
  {
    title: "Our Mission",
    paragraph:
      "At ViviLife, our mission is to empower individuals to lead healthier lives by connecting them with wellness coaches and dynamic communities. Our platform enables coaches to build communities, offer one-to-one sessions, and organize engaging community events. We strive to make wellness accessible and enjoyable for everyone, fostering a global movement towards better health.",
  },
  {
    title: "Our Story",
    paragraph:
      "From coding on a houseboat in Amsterdam to launching innovative projects, our journey has been fueled by a shared vision to make a difference. United by our passion for technology and desire to improve the world, we created ViviLife to revolutionize how people live and interact. Join us as we turn our dream into reality and make a meaningful impact together.",
  },
];

export default About;
