import community_types from "../../assets/content/community_types.json";
import { useForm } from "../../helpers/form";
import { useJourney } from "../../providers/journey_provider";
import { useState } from "react";
import "./early_access.css";
import axios from "axios";
import { useWindowSize } from "@react-hook/window-size";
import React from "react";
import Confetti from "react-confetti";
import Success from "../../assets/images/success.png";
import Button from "../button";
import { useEarlyAccess } from "../../providers/go_to_early_access.provider";
import Selection from "./selection";

export const EarlyAccess = ({ earlyAccessRef }) => {
  const { content, selectedJourney } = useJourney();
  const [showNotification, setShowNotification] = useState(false);
  const { width, height } = useWindowSize();
  const { early_access_ref } = useEarlyAccess();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const form_default_values = {
    email: "",
    community_type: "",
    type: selectedJourney,
  };

  const { values, update } = useForm(form_default_values);

  const submit = (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    // const url = "http://localhost:6777/add_waiting_user";
    const url = process.env.REACT_APP_API_URL + "/add_waiting_user";
    axios.post(url, values).then((res) => {
      window.scrollTo(0, 0, "smooth");
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 5000);
    });
  };

  return (
    <div
      className="flex flex-col bg-black rounded-xl py-6 items-center px-4"
      ref={early_access_ref}
    >
      <h1 className="text-2xl font-bold text-center text-white mt-4">
        Request early access when we launch!
      </h1>
      <form
        className="flex flex-col w-4/5 lg:w-1/2 items-center justify-center my-10 gap-6"
        onSubmit={submit}
      >
        <label className="text-white text-md font-semibold">
          {content.form_type}
        </label>
        <Selection update={(key, value) => update(key, value)} />
        <input
          className="w-full rounded-full py-3 mx-6 lg:mb-0 px-4"
          type="email"
          placeholder="email"
          value={values.email}
          onChange={(e) => update("email", e.target.value)}
        />

        <Button
          className="w-full disabled:opacity-50"
          type="submit"
          disabled={buttonDisabled}
        >
          Request Invite
        </Button>
      </form>
      {showNotification && (
        <>
          <Confetti width={width} height={height} />
          <Notification />
        </>
      )}
    </div>
  );
};

const Notification = () => {
  return (
    <div className="early_access_notification">
      <img src={Success} alt="success" className="w-12 md:w-28" />
      <div className="flex flex-col gap-4 items-center md:items-start">
        <p className="text-black font-black text-2xl md:text-6xl">
          Welcome to Vivilife!
        </p>
        <p className="text-lg">You will be notified when we are live!</p>
      </div>
    </div>
  );
};

/*
<select
          className="rounded-full w-full py-3 mx-6 lg:mb-0 px-4"
          value={values.community_type}
          onChange={(e) => update("community_type", e.target.value)}
        >
          {community_types.map((type, i) => (
            <optgroup key={i} label={type.label}>
              {type.options.map((o, i) => (
                <option key={i} value={o.value}>
                  {o.label}
                </option>
              ))}
            </optgroup>
          ))}
        </select>
*/
