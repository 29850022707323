import hero_bg_image from "../images/coach_journey.webp";
import hero_secondary_image from "../images/coach_hero.webp";
import build_your_community_image from "../images/build_your_community.webp";
import monetise_image from "../images/monetise.webp";
import streamline_your_schedule_image from "../images/streamline_your_schedule.webp";
import card_1_icon from "../images/icons/multiple_people.png";
import card_2_icon from "../images/icons/monetise.png";
import card_3_icon from "../images/icons/calendar_check.png";
import make_a_bigger_impact from "./blog/coach/make_a_bigger_impact";
import streamline_your_schedule from "./blog/coach/streamline_your_schedule";
import monetize from "./blog/coach/monetize";
import monetise from "../images/blog/monetize.webp";
import impact from "../images/blog/impact.webp";
import stream from "../images/blog/stream.webp";

const coach = {
  slogan: "MAKE YOUR PASSION PROFITABLE",
  hero_bg_image: hero_bg_image,
  hero_secondary_image: hero_secondary_image,
  hero_title: "All-in-One Platform Promote, Schedule, Connect and Grow",
  hero_subtitle:
    "Transform Your Coaching Practice. Join ViviLife and discover the ultimate platform designed exclusively for health and wellness coaches. We provide everything you need to build, manage, and monetise your community in one place.",
  paragraph_1:
    "At ViviLife, we empower coaches to turn their passion into profit while making a real difference in their clients' lives. Our platform is built to support you every step of the way, ensuring you can focus on what you do best – coaching.",
  paragraph_2: "Ready to Elevate Your Coaching Practice?",
  cards: [
    {
      icon: card_1_icon,
      image: build_your_community_image,
      title: "Make a Bigger Impact",
      description:
        "Monetizing your community on ViviLife is a great way to turn your passion into profit. By offering exclusive content, hosting paid events, and providing one-on-one coaching, you can generate income while helping others achieve their health and wellness goals",
      link: {
        text: "Learn how to make a bigger impact",
        url: "/blog/make-a-bigger-impact",
      },
    },
    {
      icon: card_2_icon,
      image: monetise_image,
      title: "Monetize",
      description:
        "Offer premium content, workshops, courses, one-to-one coaching sessions and group gatherings. Integrated payment systems for smooth transactions.",
      link: {
        text: "Learn more about Monetize",
        url: "/blog/monetize",
      },
    },
    {
      icon: card_3_icon,
      image: streamline_your_schedule_image,
      title: "Streamline Your Schedule",
      description:
        "Manage all your events and sessions in one place with our centralised calendar. Enjoy automated reminders and integrated communication for efficient scheduling.",
      link: {
        text: "Learn more about Streamline Your Schedule",
        url: "/blog/streamline-your-schedule",
      },
    },
  ],
  question: "Ready to Elevate your coaching practice?",
  mission:
    "Our mission is to empower coaches to inspire positive change and transform lives through our innovative platform.",
  form_type: "What type of coach are you?",

  blog: [
    {
      img: impact,
      title: "Make a Bigger Impact",
      postId: "make-a-bigger-impact",
      to: make_a_bigger_impact,
    },
    {
      img: monetise,
      title: "Monetize",
      postId: "monetize",
      to: monetize,
    },
    {
      img: stream,
      title: "Streamline Your Schedule",
      postId: "streamline-your-schedule",
      to: streamline_your_schedule,
    },
  ],
  blogText: "Learn about ViviLife's Commitment to Coaches",
};

export default coach;
