import React from "react";
import Button from "../components/button";
import { Container } from "../components/page/container";
import { Header } from "../components/header/header";
import { useJourney } from "../providers/journey_provider";
import logo from "../assets/images/logo.png";
import { Footer } from "../components/footer/footer";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import BlogPost from "../components/blog/post";

export const BlogRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<BlogFeed />} />
      <Route path="/:postId" element={<BlogPost />} />
    </Routes>
  );
};

const BlogFeed = () => {
  return (
    <div className="bg-gray-100">
      <Container>
        <Header />
        <BlogHeader />
        <BlogPosts />
        {/* <SeeMoreButton /> */}
        <Footer />
      </Container>
    </div>
  );
};

const BlogHeader = () => {
  const { content } = useJourney();
  return (
    <div className="mt-28 flex flex-col md:flex-row items-center justify-center py-16 px-6 gap-6 bg-black rounded-3xl">
      <div className="hidden md:flex md:w-1/2 flex-col items-center">
        <img src={logo} alt="logo" className="w-52" />
        <p className="text-xs text-white font-medium py-6">{content.slogan}</p>
      </div>
      <div className="md:w-1/2 text-center md:text-left">
        <p className="text-lg text-white font-light">ViviLife blog</p>
        <h1 className="text-3xl font-medium text-white my-6">
          {content.blogText}
        </h1>
        <p className="text-white font-light">{content.mission}</p>
      </div>
    </div>
  );
};

const BlogPosts = () => {
  const { content } = useJourney();

  return (
    <div className="flex justify-center py-10">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-12">
        {content.blog.map((post, i) => (
          <Post img={post.img} title={post.title} key={i} to={post.postId} />
        ))}
      </div>
    </div>
  );
};

const Post = ({ img, title, to }) => {
  const navigate = useNavigate();
  return (
    <button className="rounded-3xl shadow-md border w-full" onClick={() => navigate(to)}>
      <img src={img} className="w-full h-96 object-cover rounded-t-3xl" />
      <h1 className="bold text-2xl font-bold p-4 text-center">{title}</h1>
    </button>
  );
};

const SeeMoreButton = () => {
  return (
    <div className="flex justify-center">
      <Button variant="black" className="w-36 mt-4">
        See more
      </Button>
    </div>
  );
};
